import { ART_STORE } from '../../../constants';
import { CommonStoreWrapper } from '@wix/common-pro-gallery-component-wrapper';
import '../../../styles/dynamic/artStore/artstorePagesWixStyles.scss';
import '../../../styles/dynamic/artStore/FullscreenWrapperWixStyles.scss';
import '../../../styles/dynamic/commonDynamicCss.global.scss';

export default class ArtStoreGalleryWrapper extends CommonStoreWrapper {
  getSentryDSN() {
    return ART_STORE.SENTRY_DSN;
  }
}
