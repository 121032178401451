import { withStyles } from '@wix/native-components-infra';
import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import App from './GalleryWrapper';
import { ART_STORE } from '../../../constants';

export default {
  component: withSentryErrorBoundary(
    withStyles(App, {
      cssPath: ['artStoreViewer.min.css'],
      strictMode: false,
    }),
    {
      dsn: ART_STORE.SENTRY_DSN,
      config: { environment: 'Native Component' },
    },
  ),
};
